body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainLayout {
  position: absolute;
  width: 100%
}

.backgroundImage {
  background-image: url("https://prod-us-west-2.imgix.net/assets/01FFP59S0X25YQPEMBFA6HY7CE/images/01fmg4vj7k7gr2w4n3cnzv8e6c.jpg?w=1600&h=1600&fit=max&q=80&s=7eb0599e17c0381900c8da7f57b48975");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}


.panel {
  background: rgba(250, 250, 250, 0.72);
  -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  box-shadow: 0px 0px 50px rgba(100,0,0,25);
  margin: 20px auto 20px auto;
  max-width: 1000px;
  padding: 50px;

}
@media (max-width: 991px) {
  .panel {
    margin: 4px;
    border-radius: 4px;
  }
}

.header {
  margin: auto;
  margin-bottom: 40px;
}
.img-centre {
  margin: auto;
  display: block;
}

.download-button {
  width: fit-content;
  font-family: sans-serif;
  color: white;
  background-color: DodgerBlue;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 15px;
  display: inline-flex;
  border-radius: 5px;
}

.download-button:hover {
  background-color: RoyalBlue;
}

.loading-spinner {
  margin: auto;
  height: 100%;
  padding: 100px;
  text-align: center;
  font-size: 10vh;
  color: dodgerblue;
}

.error-message {
  margin: auto;
  height: 100%;
  padding: 100px;
  text-align: center;
}

.error-icon {
  font-size: 10vh;
  color: darkred;
}

.image-card {
  display: inline-block;
  padding: 1%;
}

.image-subtitle {
  width: fit-content;
  margin: auto;
  padding: 4px;
}

.sub-header {
  width: fit-content;
  margin: auto;
  position: relative;
}






.fmhv .mainGrid>.row>.noFloat.sidebar:after {
  background: rgba(250, 250, 250, 0.72) 0 0;
}

.mainBody {
  background: rgba(250, 250, 250, 0.72);
  -moz-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0,0,0,.5);
  box-shadow: 0px 0px 50px rgba(100,0,0,25);
  padding: 10px 50px 10px 50px;
}

@media (max-width: 991px) {
  .sideBarLayout {
    padding: 10px 10px 10px 10px;
    background: rgba(250, 250, 250, 0.72);
  }
  .mainBody {
    padding: 0px;
  }
}

.mainTitle, .description {
  padding: 10px;
  border-radius: 4px;
  background-color: #f7f7f7;
  text-align: center;
}

.mainTitle {
  border-radius: 4px 4px 0px 0px;
}

.fmhv .categoryList .description {
  border-radius: 0px 0px 4px 4px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}

.fmhv .categoryList .category button.btn-primary {
  background-color: #f7f7f7;
}

.fmhv .sideBarLayout .brand img {
  float: left;
}

@media (max-width: 991px) {
  .fmhv .sideBarLayout .brand img {
    float: none;
    margin: auto;
  }
}

.galleryViewView, .galleryListView {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px 4px 4px 4px;
  background-color: #f7f7f7;
}

.panel-body {
  background-image: url("https://prod-us-west-2.imgix.net/assets/01FFP59S0X25YQPEMBFA6HY7CE/images/617115710ff5e.png?w=700&h=700&fit=max&q=80&s=69bf593f057acba0ab3c1ad1fd9a81e1");
  background-size: 100% 100%;
  padding: 50px;
}

@media (max-width: 991px) {
  .panel-body {
    padding: 50px 50px 50px 50px;
  }
}
/*

#custom_logo {
  background-image: url("https://prod-us-west-2.imgix.net/assets/01FFP59S0X25YQPEMBFA6HY7CE/images/616e2a3429a90.png?w=200&q=75&fit=clip&s=1d712fca23bbb27d72d6943931a93742");
  height: 500px;
}

#custom_logo > img {
	opacity: 0;
}

*/